import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./Header.css";
import zincLogo from "../../util/zincLogo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyCart } from "../../util/icons";

const Header = ({ store }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => {
    setExpanded(false);
  };

  const data = useSelector((state) => state.cart.cartList);
  return (
    <Navbar expand="lg" expanded={expanded} variant="dark" className="header">
      <Container>
        <div className="mobile">
          <Navbar.Toggle
            className="mobile-left"
            onClick={() => setExpanded(!expanded)}
          />
          <Nav className="mobile-center-logo">
            <Nav.Link
              className="nav-c-link"
              href="#HOME"
              onClick={handleExpanded}
            >
              <img alt="" src={zincLogo} className="zincLogo" />
            </Nav.Link>
          </Nav>
        </div>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="mobile_left_logo"
        >
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/"
              onClick={handleExpanded}
            >
              <img
                alt=""
                className="graffiti3"
                src={`${process.env.REACT_APP_IMAGE_URL}${store.icon_image_path}`}
              />
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/about"
              onClick={handleExpanded}
            >
              ABOUT US
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/booking"
              onClick={handleExpanded}
            >
              BOOKING
            </Nav.Link>
          </Nav>
          {/* <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/service"
              onClick={handleExpanded}
            >
              PACKAGE
            </Nav.Link>
          </Nav> */}
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/package"
              onClick={handleExpanded}
            >
              PACKAGE
            </Nav.Link>
          </Nav>
          <Nav className="mobileShow">
            <Nav.Link
              as={Link}
              className="nav-b-link"
              to="/"
              onClick={handleExpanded}
            >
              {" "}
              <img alt="" src={zincLogo} width="70" />
            </Nav.Link>
          </Nav>
          {/* <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/menu"
              onClick={handleExpanded}
            >
              MENU
            </Nav.Link>
          </Nav> */}
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/special_promotion"
              onClick={handleExpanded}
            >
              SPECIAL PROMOTION 
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/gallery"
              onClick={handleExpanded}
            >
              GALLERY
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              as={Link}
              className="nav-a-link"
              to="/contact"
              onClick={handleExpanded}
            >
              CONTACT
            </Nav.Link>
          </Nav>
          <Nav>
            <div className="cart_nav">
              <Link to="/cart" onClick={handleExpanded} className="cart_style">
                <span className="cart_length">{data?.length}</span>
                <div className="social-btn">
                  <MyCart />
                </div>
              </Link>

              <h6 className="social-text">My cart</h6>
          </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
